body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body a:hover {
  color: #ccc !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.1) !important;
}