@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  root {
    flexGrow: 1;
  }
  flex {
    flex: 1;
  }
  menuButton {
    margin-left: -12;
    margin-right: 20;
  }
  card {
    min-width: 275;
  }
  bullet {
    display: 'inline-block';
    margin: '0 2px';
    transform: 'scale(0.8)';
  }
  title {
    margin-bottom: 16;
    font-size: 14;
  }
  pos {
    margin-bottom: 12;
  }
}
